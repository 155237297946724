.heading {

	h3, .h3 {
		font-size: 35px;

		@include media('>phone') {
			font-size: calc(35px + 8 * ((100vw - 320px) / 960));
		}
		@include media('>desktop') {
			font-size: $type-largest;
		}

	}

	h4, .h4 {
		font-size: $type-mid;

		@include media('>phone') {
			font-size: calc(24px + 8 * ((100vw - 320px) / 960));
		}
		@include media('>desktop') {
			font-size: $type-large;
		}

	}
}
