header {
	background: rgba(255,255,255,0.9);
	border-bottom: 2px solid color('green');
	transition: all 0.5s;
	@extend %clearfix;

	.header-content {
		width: 95%;
		padding: 15px 30px;
		margin: 0 auto;

		@include media('>wrapper-big') {
			width: 1230px;
		}
	}

	.logo {
		height: 35px;
		transition: height 0.5s;

		@include media('>950px') {
			height: 50px;
		}
	}
}

.headerOnScroll {
	&.fixed {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 99999;

		.logo {
			height: 30px;
			transition: height 0.5s;
		}

		nav {
			margin: 0;

			ul li {
				padding: 0 0 20px 30px;
			}
		}

		.sub-menu ul {
			top: 45px;

			li {
				padding: 10px 25px;
			}
		}
	}
}
