#accordion {
	float: left;
	width: 100%;
	margin-bottom: 15px;
	
	@include media('>grid') {
		margin-bottom: 30px;
	}
	
	&.open .button-more::after {
		background: url('../images/icons/angle-up.svg') no-repeat;
		background-size: 10px;
	}

	#accordion-wrapper {
		width: 90%;
		display: none;
		position: relative;
		margin-top: 30px;
		text-align: left;
		padding: 30px 30px 35px;
		background-color: color('grey', 'light');
		hyphens: none;

		ul {
			margin: 10px 0 20px;
			padding-left: 16px;
		}

		p {
			margin: 0;
		}

		.price {
			padding: 31px 8px;
			border-radius: 50%;
			text-align: center;
			line-height: 80px;
			color: #fff;
			background-color: color('petrol');

			@include media('>phone') {
				position: absolute;
				top: 35%;
				right: 30px;
				transform: translateY(-50%);
				width: 80px;
				height: 80px;
				padding: 0;
			}
		}

		.button-brown {
			margin-top: 30px;

			@include media('>phone') {
				margin-top: 0;
			}
		}
		
	}
}