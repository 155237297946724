.arrival {
	.content {
		padding: 60px 30px 35px;
	}

	h2 {
		margin-bottom: 5px;
		left: auto;
		transform: none;
	}
}
