nav {
	float: right;

	@include media('>950px') {
		margin-top: 12px;
	}

	ul {
		display: none;
		position: absolute;
		top: 65px;
		left: 0;
		width: 100%;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		padding: 25px 50px;
		background-color: color('grey', 'dark');
		z-index: 99999;

		@include media('>950px') {
			display: block;
			position: relative;
			top: 0;
			padding: 0;
			background-color: transparent;
		}

		li {
			@include media('>950px') {
				display: inline-block;
				padding: 0 0 28px 30px;

				&:first-child {
					padding: 0 0 28px 0;
				}
			}
		}

		a {
			display: block;
			color: #fff;
			padding: 5px 10px;

			&.active,
			&:hover {
				background-color: color('green');
			}

			@include media('>950px') {
				color: color('grey');
				padding: 0;


				&.active,
				&:hover {
					color: color('petrol', 'base');
					background-color: transparent;
				}
			}
		}
	}

	.sub-menu {
		@include media('>950px') {
			position: relative;

			#sub-menu {
				margin-right: 15px;

				&::after {
					content: '';
					background: url('../images/icons/angle-down.svg') no-repeat;
					background-size: 10px;
					position: absolute;
					top: 5px;
					right: 0;
					width: 10px;
					height: 12px;
				}

				&.active, &:hover {
					&::after {
						content: '';
						background: url('../images/icons/angle-down_orange.svg') no-repeat;
						background-size: 10px;
						position: absolute;
						top: 5px;
						right: 0;
						width: 10px;
						height: 12px;
					}
				}
			}
		}

		ul {
			display: block;
			position: relative;
			top: 0;
			padding: 10px 0 10px 20px;
			background-color: transparent;

			@include media('>950px') {
				display: none;
				position: absolute;
				top: 53px;
				left: auto;
				width: 180px;
				padding: 0;
				margin-left: -10px;
				background-color: #fff;
				border-top: 2px solid color('green');
				border-bottom: 2px solid color('green');

				li {
					padding: 10px 25px;

					/*&:last-child {
						border-top: 1px solid color('green');
					}*/
				}
			}
		}
	}
}

.toggleMenu {
	display: block;
	padding: 5px 9px;
	background-color: color('green');

	@include media('>950px') {
		display: none;
	}

	img {
		height: 20px;
		vertical-align: -4px;
	}
}