main {
	background-color: #fff;
	@extend .wrapper;
}

.welcome, .gallery, .attraction, .rooms, .contact {
	padding: 60px 30px;
}

.regime {
	padding-bottom: 35px;
	padding-left: 30px;

	ul {
		margin-bottom: 25px;
	}
}

.not-found {
	padding: 60px 30px 35px;

	h3, p {
		text-align: center;
	}
}