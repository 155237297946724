#go-to-top {
	@include media('>610px') {
		height: 40px;
		margin-top: 20px;
	}

	@include media('>1400px') {
		height: auto;
		margin-top: 0; 
	}

	img {
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 40px;
		height: 40px;
		padding: 6px 8px 8px;
		background-color: color('petrol');
		box-shadow: 0 0 5px 0 rgba(0,0,0,0.1);
		border-radius: 5px;
		z-index: 9;
		cursor: pointer;

		@include media('>1400px') {
			left: auto;
			transform: none;
			bottom: 15px;
			right: 20px;
		}
	}
} 
