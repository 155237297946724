.title-area {
	color: #fff;
	padding: 50px 30px 20px 30px;
	background-color: color('green');
	margin-bottom: 60px;

	h1 {
		text-align: left;
		font-size: $type-mid;

		@include media('>phone') {
			font-size: 30px;
		}

		@include media('>tablet') {
			font-size: 36px;
		}
	}

	p {
		font-size: $type-base;
		margin: 0;

		@include media('>phone') {
			font-size: 20px;
		}
	}
}

.reservierung {
	padding: 50px 30px;
	margin-bottom: 0;
}