/**
 * Global Color palette
 * - Generic colors
 * - Color scheme
 * - Common colors
 * - Links
 * - Text selection
 * - <hr>
 * - Any others..
 */

// Generic colors

$color-key: 'base' !default;

$colors: (
    'primary': (
        'base': #8e3329,
        'light': #d9534f,
        'dark': #c9302c
    ),
    'secondary': (
        'base': #5a1321,
        'light': #7b1a2d,
        'dark': #51111e
    ),
    'foreground': (
        'base': #191919,
        'light': #333333,
        'dark': #111111,
        'darker': #000000
    ),
    'grey': (
        'base': #5A5A5A,
        'light': #efefef,
        'light-footer': #8a8a8a,
        'light-table': #c6c6c6,
        'dark': #565656
    ),
    'red': (
        'base': #bd0f1e
    ),
    //brown !!
    'green': (
        'base': #918069
    ),
    //orange !!
    'petrol': (
        'base': #e08910,
        'dark': #fc9d1a
    ),
    'blue': (
        'base': #edf4fa
    )
);

//'yellow': #fff,

$opacity: (
        'light': 0.8, // opacity used with lighter colors
        'dark': 0.4   // opacity used with darker colors
        // ... etc.
);


// Color Scheme
$color-primary                	: color('petrol', 'base');
$color-secondary              	: color('green', 'base');
$color-highlight              	: $color-primary;


// Common colors
$color-text                   	: color('grey', 'dark');
$color-bg                     	: #fff;
$color-border                 	: #ddd;


// Links
$color-link-default           : $color-highlight;
$color-link-visited           : $color-link-default;
$color-link-hover             : $color-secondary;
$color-link-active            : $color-secondary;

// Text Selection
$color-selection              : #fff;
$color-selection-bg           : $color-primary;


// <hr> border color
$hr-border                    : lighten(#000, 70%);


// 3rd party brand colors - find more at brandcolors.net
$color-twitter                : #55acee;
$color-facebook               : #3b5998;
$color-youtube                : #cd201f;
$color-pinterest              : #bd081c;
$color-instagram              : #3f729b;


// Form validation colors
$state-success: #468847;
$state-error:   #b94a48;
$form-border-color-focus: $color-primary;
$form-submit-color: $color-primary;
$form-submit-color-hover: #444;


