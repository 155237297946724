#tabs .ui-tabs-panel {
	ul {
		margin-bottom: 20px;
	}

	p, li {
		color: $color-text;
	}

	a {
		color: color('petrol', 'base');

		&:hover {
			color: color('petrol', 'dark');
		}
	}
}
