footer {
	color: #fff;
	@extend .wrapper;

	span {
		font-size: $type-base;
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 10px;
	}

	a {
		color: #fff;
	}

	p {
		margin: 0;
	}

	.top-footer {
		padding: 45px 30px 15px;
		background-color: color('grey', 'light-footer');
		@extend %clearfix;

		.social-icons-link {
			margin-top: 10px;
			display: block;

			.facebook {
				width: 30px;
				height: 30px;
				padding: 6px;
				margin-right: 10px;
				background-color: $color-facebook;
				border-radius: 5px;
			}

			.twitter {
				width: 30px;
				height: 30px;
				padding: 6px;
				margin-right: 10px;
				background-color: $color-twitter;
				border-radius: 5px;
			}
		}
	}

	.bottom-footer {
		padding: 20px 30px;
		background-color: color('grey', 'dark');

		ul {
			display: none;

			@include media('>tablet') {
				display: block;
				text-align: right;

				li {
					display: inline-block;
					padding-left: 30px;

					&:first-child {
						padding-left: 0;
					}
				}

				a {
					font-size: $type-small;
					text-transform: uppercase;
				}
			}
		}
	}
}

.footer {
	margin-bottom: 25px;
}

