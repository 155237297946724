/**
 * Typography
 * - Paragraphs
 * - Headings
 * - Type sizes
 * - Misc
 * - Utilities
 *
 * Body font size, leadings etc have been set in _vars.scss
 *
 */

/**
 * Paragraphs
 */
p {
	margin-top: 0;
	margin-bottom: $base-line-height;
	font-weight: 400;
	// Measure - ideally about 65 chars per line
	// max-width: $type-base * 30 + px;

	// & small {
	// 	color: lighten($color-text, 10%);
	// }
}

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
	font-family: $font-family-headings;
	font-weight: $font-weight-headings;
	line-height: $line-height-headings;
	text-rendering: optimizelegibility; // Fix the character spacing for headings
	margin-top: 0;
	margin-bottom: $base-line-height;

	small {
		font-weight: normal;
	}
}

// Use the helper classes to emulate styles for another element
// For example: h3.h1 or h3.alpha
h1, .h1 {
	font-size: 26px;
	margin-bottom: 30px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;

	@include media('>phone') {
		font-size: $type-largest;
	}
}

h2, .h2 {
	display: inline-block;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	font-size: $type-large;
	font-weight: 600;
	padding-bottom: 10px;
	border-bottom: 2px solid color('green', 'base');
}

h3, .h3 {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 15px;
}

h4, .h4 {
	font-size: $type-mid;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 5px;
}

h5, .h5,
h6, .h6 {
	font-size: $type-mid;
	font-style: italic;
	font-weight: 300;
	text-align: center;
	color: color('grey', 'base');
	margin-bottom: 60px;
}

// Only give these headings a margin-top if they are after other elements
* + h1, * + .h1,
* + h2, * + .h2,
* + h3, * + .h3,
* + h4, * + .h4 {
	margin-top: $base-line-height;
}

small {
	font-size: 80%;
}

/**
 * Miscellaneous
 */

// Emphasis
strong, b {
	font-weight: $base-font-weight-bold;
}

em, i {
	font-style: italic;
}

// Abbreviations and acronyms
abbr[title] {
	border-bottom: 1px dotted #ddd;
	cursor: help;
}

/**
 * Blockquotes
 */
blockquote {
	padding-left: 10px;
	margin: $base-line-height;
	border-left: 4px solid lighten(#000, 80%);

	p {
		margin-bottom: 0;
		font-weight: 300;
	}

	small {
		display: block;
		color: lighten(#000, 70%);

		&:before {
			content: '\2014 \00A0';
		}
	}
}

// Quotes
q,
blockquote {
	&:before,
	&:after {
		content: "";
	}
}

cite {
	font-style: normal;
}

// Addresses styling not present in S5, Chrome
dfn {
	font-style: italic;
}

// Addresses styling not present in IE6/7/8/9
mark {
	background: yellow;
	padding: 2px 4px;
	border-radius: 3px;
}

// Prevents sub and sup affecting line-height in all browsers
// gist.github.com/413930
sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup { top: -.5em; }
sub { bottom: -.25em; }

samp {
	font-family: $font-family-mono;
}

// Horizontal rules
hr {
	margin: ($base-line-height) 0;
	border: 0;
	border-top: 1px solid color('green');
	border-bottom: 1px solid color('green');
}

/* arima-madurai-regular - latin */
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/arima-madurai/arima-madurai-v6-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Arima Madurai'), local('ArimaMadurai-Regular'),
       url('../fonts/arima-madurai/arima-madurai-v6-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-regular.svg#ArimaMadurai') format('svg'); /* Legacy iOS */
}
/* arima-madurai-300 - latin */
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/arima-madurai/arima-madurai-v6-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Arima Madurai Light'), local('ArimaMadurai-Light'),
       url('../fonts/arima-madurai/arima-madurai-v6-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-300.svg#ArimaMadurai') format('svg'); /* Legacy iOS */
}
/* arima-madurai-500 - latin */
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/arima-madurai/arima-madurai-v6-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Arima Madurai Medium'), local('ArimaMadurai-Medium'),
       url('../fonts/arima-madurai/arima-madurai-v6-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-500.svg#ArimaMadurai') format('svg'); /* Legacy iOS */
}
/* arima-madurai-700 - latin */
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/arima-madurai/arima-madurai-v6-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Arima Madurai Bold'), local('ArimaMadurai-Bold'),
       url('../fonts/arima-madurai/arima-madurai-v6-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/arima-madurai/arima-madurai-v6-latin-700.svg#ArimaMadurai') format('svg'); /* Legacy iOS */
}