fieldset {
	position: relative;
	border: none;
	margin: 0 0 15px;
	padding: 0;
}

label {
	display: block;
	margin-bottom: 10px;
	font-weight: 600;
	
	span {
		font-size: 13px;
		font-weight: 400;
	}
}

input[type="text"],
input[type="email"],
textarea {
	background: none;
	border-radius: 5px;
	border: 2px solid;
	border-color: color('petrol', 'base');
	padding: 14px 15px;
	width: 100%;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;

	background: none;
	border-radius: 5px;
	border: 2px solid;
	width: 100%;
	padding: 14px 15px;
	cursor: pointer;
}

input[type="submit"] {
	font-family: $base-font-family;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	color: #fff;
	height: 50px;
	min-width: 150px;
	padding: 17px 15px;
	background-color: color('petrol', 'base');
	border: none;
	border-radius: 5px;
	transition: all 2s ease;
	cursor: pointer;

	&:hover {
		background-color: color('petrol', 'dark');
	}
}

textarea {
	resize: none;
	height: 150px;
}

.icon-datepicker {
	position: relative;
}

.icon-select {
	position: relative;
}

label.error {
	position: absolute;
	right: 0;
	top: 0;
	color: color('red', 'base');
	font-size: $type-small;
}

.contact-form {
	margin-top: 30px;
	@extend %clearfix;

	input[type="submit"] {
		width: 100%;
		margin-top: 22px;

		@include media('>phone') {
			float: right;
			width: auto;
		}
	}
}

#privacy {
	margin-top: 30px;
	
	@include media('>1000px') {
		margin-top: 0;
	}

	span {
    	font-size: 13px;
    }
    
    a {
	    word-break: break-all;
    }
}

input[type="checkbox"],
input[type="radio"] {
	-webkit-appearance: none;
	-moz-appearance: none;

	position: relative;
	width: 15px;
	height: 15px;
	margin-right: 8px;
	background-color: transparent;
	border: 2px solid color('petrol', 'base');
	cursor: pointer;
	overflow: hidden;

	@media screen and (min-width:0\0) {
		border: none;
	}
}

input[type="checkbox"]:checked {
	background: url('../images/icons/check_brown.svg') no-repeat center;
	background-size: 9px;
}

.booking-form {
	select {
		background: url('../images/icons/angle-down_orange.svg') no-repeat center right 15px;
		background-size: 16px;
		border-color: color('petrol', 'base');
		height: 50px;
	}

	.datepicker {
		background: url('../images/icons/calendar_orange.svg') no-repeat center right 15px;
		background-size: 16px;
		cursor: pointer;
	}

	input[type="checkbox"]:checked + label {
		color: color('petrol', 'base');
		font-weight: 600;
	}

	input[type="radio"] {
		border-radius: 50%;

		&:checked {
			background: url('../images/icons/dot.svg') no-repeat center;
			background-size: 9px;
		}
	}

	input[type="radio"]:checked + label {
		color: color('petrol', 'base');
		font-weight: 600;
	}

	input[type="submit"] {
		width: 100%;
		margin-top: 30px;

		@include media('>1000px') {
			margin-top: 46px;
			
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  /* Internet Explorer 10+ */
				margin-top: 71px;
			}
		}
	}
	
	input[type="text"]:disabled {
		background: color('grey', 'light');
		border-color: #dddddd;
	}
	
	#firstoption {
		margin-top: 8px;
	}
	
	.agemargin {
		margin-bottom: 0 !important;
	}
}

.contact-form, .booking-form {
	input[type="text"],
	input[type="email"] {
		height: 50px;
	}
	
	label {
		height: 25px;
	}
}