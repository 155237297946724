//------------------------------------//
//  $VARIABLES
//------------------------------------//


// Fonts
$font-family-sans             	: "Arima Madurai", Helvetica, Arial, sans-serif;
$font-family-serif            	: Georgia, "Times New Roman", Times, serif;
$font-family-mono             	: Menlo, Monaco, "Courier New", monospace;

// Base
$base-font-size					: 16px;
$base-line-height				: 25px;
$base-font-size--mobile			: $base-font-size;
$base-font-weight				: 400;
$base-font-weight-bold			: bold;

$base-font-family             	: $font-family-sans;
$base-font-family-alt         	: $font-family-serif;

$font-family-headings         	: $base-font-family; // using inherit will use $font-family-base for all headings
$font-weight-headings         	: 400;    // instead of browser default, bold
$line-height-headings         	: 1.1;

$type-largest					: 36px;
$type-large						: 32px;
$type-mid						: 18px;
$type-base						: $base-font-size;
$type-small						: 13px;


// Calculated measurements
$line-height-ratio: $base-line-height / $base-font-size;
$base-spacing-unit: $line-height-ratio;
$half-spacing-unit: $base-spacing-unit / 2;

// Grid
$grid-cols: 12;
$susy: (
	columns: $grid-cols,
	gutters: 1/4,
	global-box-sizing: border-box
);
$grid-gutter: 2%; // Easy Grid Scss

// Master Breakpoints
$breakpoints: (
	'phone': 480px,
	'tablet': 640px,
	'grid': 900px,
	'desktop': 1050px,
	'wrapper': 1200px,
	'wrapper-big': 1280px
);