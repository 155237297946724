/*!
 * phoenixBase
 *
 * @author: Rene Kreupl
 * @copyright: 2016 by phoenixlabs.de
 * @repository: https://github.com/phoenixlabs/phoenixBase
 * @version: 1.3.0;
 */


@import "vendor/normalize";
@import "vendor/include-media";
@import "../../node_modules/susy/sass/susy";

@import "core/color";
@import "core/fluid-type";

@import "color";
@import "vars";

@import "core/base";
@import "core/helper-classes";
@import "core/grid";
@import "core/svg";

@import "modules/pace";
//@import "modules/typo3";
//@import "modules/wordpress";
@import "modules/jquery-ui";
@import "modules/bxslider";
@import "modules/magnific-popup";
@import "modules/cookie-consent";

@import "ui/typography";
@import "ui/form";
@import "ui/heading";
@import "ui/special-links";
@import "ui/button";
@import "ui/tabs";
@import "ui/accordion";

@import "elements/wrapper";
@import "elements/page";
@import "elements/header";
@import "elements/emotion";
@import "elements/booking-area";
@import "elements/title-area";
@import "elements/navigation";
@import "elements/welcome";
@import "elements/apartment";
@import "elements/gallery";
@import "elements/attraction";
@import "elements/arrival";
@import "elements/rooms";
@import "elements/room-detail";
@import "elements/contact";
@import "elements/booking";
@import "elements/sidebar";
@import "elements/call-area";
@import "elements/footer";
@import "elements/map";
@import "elements/arrow-top";