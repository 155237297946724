.call-area {
	text-align: center;
	font-size: 24px;
	line-height: 35px;
	color: #fff;
	padding: 40px 30px;
	background-color: color('green');

	a {
		display: block;
	}

	.button-orange {
		margin-top: 25px;
		width: 100%;

		@include media('>phone') {
			width: 230px;
		}
	}
}