html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

//body
body {
	margin: 0;
	padding: 0;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-weight: $base-font-weight;
	color: $color-text;
	background: #d5ccbd;
	background-size: cover;
	hyphens: auto;

	//@include breakpoint('s') {
	//	font-size: $base-font-size--mobile;
	//}

	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: $color-primary !important;

	// Add ligatures
	//font-feature-settings: "liga","dlig";

	// Two ways to smoothen type, they are disabled by default
	// -webkit-font-smoothing: antialiased;
	//    -moz-font-smoothing: antialiased;
	// font-smooth:always;
}


//selection
::selection,
::-moz-selection {
	background: $color-selection-bg;
	color: $color-selection;
	text-shadow: none;
}


/* === Images === */
img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}

/* === Links === */
a {
	transition: all .25s;
	text-decoration: none;
	cursor: pointer;
}

main, footer {
	a {
		color: $color-link-default;

		&:hover {
			text-decoration: underline;
		}
		&:hover,
		&:focus {
			outline: 0;
		}

		img {
			border: 0;
		}
	}
}

/* === iFrames === */
iframe {
	max-width: 100%;
}

/* === list === */
ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {

	}
}

main ul li {
	background: url('../images/icons/dot-brown.svg') no-repeat left center;
	padding-left: 15px;
	background-position-y: 7px;
}