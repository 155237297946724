.contact {
	h3 {
		margin-top: 25px;
		margin-bottom: 30px;
	}

	p {
		margin: 0;
	}

	ul {
		margin: 8px 0;
	}

	.col8 {
		@include media('>desktop') {
			@include span(8 of 12);
		}

		.contact-data {
			@include media('>tablet') {
				@include span(4 of 12);
			}
		}

		.arrival-description {
			@include media('>tablet') {
				@include span(8 of 12 last);
			}
		}
	}

	.col4 {
		width: 100%;
		margin: 0;

		@include media('>desktop') {
			@include span(4 of 12 last);
		}
	}
}
