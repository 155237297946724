.booking {
	padding: 60px 30px 45px;

	.thanks h3 {
		margin-bottom: 30px;
	}

	.col4 {
		width: 100%;
		margin: 0 0 30px;

		&:last-child {
			margin: 0;
		}

		@include media('>1000px') {
			@include span(4 of 12);

			&:nth-child(3n+3) {
				@include last;
			}
		}
	}

	.col6 {
		@include media('>tablet') {
			@include span(6 of 12);

			&:last-child {
				@include last;
			}
		}
	}
	
	.col8 {
		@include media('>1000px') {
			@include span(8 of 12);

			&:last-child {
				@include last;
			}
		}
	}

	.options {
		span {
			font-weight: 600;
		}

		label {
			cursor: pointer;
			display: inline-block;
			font-weight: 400;
			margin-bottom: 0;
			vertical-align: 2px;
		}
	}
}