.emotion {
	position: relative;
	padding: 0;
	@extend .wrapper;

	img {
		width: 100%;
	}

	.slider-caption {
		display: none;

		@include media('>800px') {
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 30px;
			font-weight: 600;
			color: #fff;
			width: auto;
			text-shadow: 0 0 20px rgba(0,0,0,0.5), 0 0 4px rgba(0,0,0,0.5);

			@media screen and (min-width:0\0) and (min-resolution:.001dpcm) {
				text-shadow: none;
				filter: dropshadow(color=#9d9d9c,offX=-1px,offY=-1px);
			}
		}
	}
}