.aside {
	padding: 0 30px 60px;

	@include media('>desktop') {
		@include span(4 of 12);
		@include last;
		padding: 60px 30px 0 0;
	}

	h4 {
		display: inline-block;
		padding-bottom: 10px;
		margin-bottom: 15px;
		border-bottom: 2px solid color('green');
	}

	.request-form {
		@extend %clearfix;

		.datepicker {
			background: url('../images/icons/calendar_orange.svg') no-repeat center right 15px;
			background-size: 16px;
			cursor: pointer;
		}

		input[type="submit"] {
			margin-top: 10px;
			width: 100%;

			@include media('>phone') {
				float: right;
				width: auto;
			}
		}
	}
}
