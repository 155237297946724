.arrival-map {
	height: 250px;

	@include media('>phone') {
		height: 350px;
	}

	@include media('>tablet') {
		height: 420px;
	}
}

.contact-map {
	height: 510px;
	margin: 20px 0 30px;

	@include media('>desktop') {
		margin: 20px 0 0;
	}
}