.welcome {
	.col4 {
		position: relative;
		text-align: center;
	}

	.circle-image {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		width: 180px;
		height: 180px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		border-radius: 50%;
	}

	.circle-border {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 190px;
		height: 190px;
		border: 2px solid color('green', 'base');
		border-radius: 50%;
		top: -5px;
	}

	.image01 {
		background-image: url('../images/kreis1.jpg');
	}

	.image02 {
		background-image: url('../images/kreis2.jpg');
	}

	.image03 {
		background-image: url('../images/kreis3.jpg');
	}
}