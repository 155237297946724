/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */


/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
	position: relative;
	margin: 0 auto;
	padding: 0;
	*zoom: 1;
}

.bx-wrapper img {
	display: block;
}

/** THEME
===================================*/

.bx-wrapper .bx-viewport {
	background: #fff;
}

.bxslider, .bxslider-room {
	margin: 0;
	padding: 0;

	li {
		width: 100% !important;
		padding-left: 0;
	}
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: -30px;
	width: 100%;
}

.bx-pager-container {
	@extend %clearfix;
}

#bx-pager {
	float: left;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	margin: 20px auto 0;
	@extend %clearfix;
	
	a {
		display: block;
		float: left;
		margin-left: 15px;
		background: #000;
		
		&:first-child {
			margin-left: 0;
		}
		
		&.active {
			background: none;
			
			img {
				opacity: 1;
			}
		}
		
		&:hover {
			background: none;
			
			img {
				opacity: 1;
			}
		}
		
		img {
			opacity: 0.5;
			transition: opacity 0.5s;
			width: 35px;

			@include media('>phone') {
				width: 60px;
			}

			@include media('>580px') {
				width: 80px;
			}
		}
	}
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	background: url('../images/slider/bx_loader.gif') center center no-repeat #fff;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
	left: 30px;
	background: url('../images/icons/angle-left.svg') no-repeat center, rgba(color('petrol', 'base'),0.6);
	border-radius: 50%;
	padding: 22px;

	&:hover {
		background-color: rgba(color('petrol', 'base'),0.8);
	}

	@include media('>800px') {
		left: 50px;

	}
}

.bx-wrapper .bx-next {
	right: 30px;
	background: url('../images/icons/angle-right.svg') no-repeat center, rgba(color('petrol', 'base'),0.6);
	border-radius: 50%;
	padding: 22px;

	&:hover {
		background-color: rgba(color('petrol', 'base'),0.8);
	}

	@include media('>800px') {
		right: 50px;
	}
}

.bx-wrapper .bx-controls-direction a {
	display: none;

	@include media('>phone') {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		outline: 0;
		z-index: 9999;
	}
}

.bx-wrapper .bx-controls-direction a.disabled {
	display: none;
}