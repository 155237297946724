$grid-cols: 12 !default;
$grid-gutter: 3% !default;

$one_col: (100% - ($grid-gutter * ($grid-cols - 1))) / $grid-cols;

@mixin grid-cols($num) {
    width: ($one_col * $num) + ($grid-gutter * ($num - 1));
}

// build grid output
.columns {
    @extend %clearfix;

    @for $i from 1 through $grid-cols {
        .span#{$i} {
            width: 100%;
            margin: 0 0 $grid-gutter 0;

			@include media('>grid') {
                @include grid-cols($i);
                margin: 0 0 0 $grid-gutter;
                float: left;

                &:first-child,
                &.first-child {
                    margin: 0;
                }
            }

			@include media('<grid') {
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}

.container {
    @include container;
}

.col2 {
    width: 100%;

    @include media('>wrapper') {
        @include span(2 of 12);
    }

    &:last-child {
        @include last;
    }
}

.col3 {
    margin-bottom: 30px;

    @include media('>grid') {
        @include span(3 of 12);

        &:last-child {
            @include last;
        }
    }
}

.col4 {
    @include media('>tablet') {
        @include span(6 of 12);

        &:nth-child(2n+2) {
            @include last;
        }
    }

    @include media('>grid') {
        @include span(4 of 12);
        margin-bottom: 0;

        &:nth-child(2n+2) {
            @include span(4 of 12);
        }

        &:nth-child(3n+3) {
            @include last;
        }
    }
}

.col6 {
    @include media('>grid') {
        float: left;
        width: 50%;
    }
}