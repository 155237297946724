.button-orange {
	position: relative;
	font-family: $base-font-family;
	font-size: 15px;
	font-weight: 600;
	text-transform: uppercase;
	color: #fff;
	width: 100%;
	height: 50px;
	padding: 17px 15px;
	background: color('petrol', 'base');
	border: none;
	border-radius: 5px;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 50px;
		background: rgba(255,255,255,0.2);
		border-radius: 5px;
		transition: all 2s ease;
	}

	&:hover::before {
		width: 100%;
	}
}

.button-more {
	margin-top: 50px;
	margin-right: 40px;
	border: none;
	font-family: $base-font-family;
	font-size: 15px;
	text-transform: uppercase;
	padding: 0 20px;
	color: #fff;
	background-color: color('green');
	width: 90%;
	height: 45px;
	transition: 0.3s ease;
	

	&::after {
		content: '';
		display: inline-block;
		background: url('../images/icons/angle-down_white.svg') no-repeat;
		background-size: 10px;
		width: 10px;
		height: 13px;
		vertical-align: 0;
		margin-left: 10px;
	}

	&:hover {
		cursor: pointer;
		padding: 0 25px;
	}
}

