.attraction {
	&.background {
		background-color: color('grey', 'light');

		/*@include media('>grid') {
			background: url('../images/hintergrund/background-attraction.jpg');
		}*/
	}

	h1 {
		margin-bottom: 50px;
	}

	img {
		margin: 5px 0 20px;

		&:hover {
			opacity: 0.7;
		}
	}

	.button-orange {
		@include media('>phone') {
			width: 140px;
		}
	}

	.hr-small {
		width: 25%;
		margin: 30px auto 50px;
	}

	.col4 {
		text-align: center;
		margin-bottom: 50px;

		@include media('>tablet') {
			&:nth-child(odd) {
				clear: both;
			}
		}

		@include media('>grid') {
			margin-bottom: 0;

			&:nth-child(odd) {
				clear: inherit;
			}

			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}
}