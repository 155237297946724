.apartment {
	background: color('grey', 'light');
	hyphens: none;

	.content {
		padding: 60px 30px 35px;
	}

	.small-separation {
		background-color: color('green');
		height: 2px;
		width: 50px;
		margin-bottom: 30px;
	}

	.image {
		background: url('../images/ferienhaus/ferienhaus-anna.jpg') center no-repeat;
		background-size: cover;
		width: 100%;
		height: 300px;

		@include media('>grid') {
			height: 658px;
		}
	}
}