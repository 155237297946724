.room-detail {
	padding: 60px 30px;

	@include media('>desktop') {
		@include span(8 of 12);
		padding: 60px 0 60px 30px;
	}

	.col4 {
		margin-bottom: 0;
	}

	h3 {
		margin: 40px 0 20px;
	}
	
	p {
		hyphens: none;
	}

	table {
		width: 100%;
		border-spacing: 0;

		th {
			display: block;
			font-weight: 400;
			font-size: $type-mid;
			color: #fff;
			width: 100%;
			padding: 10px;
			background-color: color('green');

			@include media('>phone') {
				width: 50%;
				display: table-cell;
			}
		}

		td {
			display: block;
			text-align: center;
			width: 100%;
			padding: 10px;
			border: 1px solid color('grey', 'light-footer');
			border-top: none;

			@include media('>phone') {
				width: 50%;
				display: table-cell;

				&:last-child {
					border-left: none;
				}
			}
		}

		tbody tr:nth-child(even) td {
			background-color: color('grey', 'light-table');
		}
	}
}