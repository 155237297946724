.rooms {
	text-align: center;

	h3 {
		margin-top: 35px;

		&:hover {
			color: color('green');

		}
	}

	.col6 {
		@include media('>grid') {
			@include span(6 of 12);

			&:last-child {
				@include last;
			}
		}
		
		a {
			word-break: break-all;
		}
	}

	.button-orange {
		width: 100%;

		@include media('>phone') {
			width: 230px;
		}
	}

	a {
		color: color('petrol', 'base');

		&:hover {
			text-decoration: none;
		}
	}

	img {
		margin: 20px 0;

		&:hover {
			opacity: 0.7;
		}
	}
}

.datenschutz {
	h2, h3 {
		word-break: break-word;
	}
	
	h3 {
		&:hover {
			color: #565656;

		}
	}
}