.booking-area {
	padding: 30px 50px;
	background: color('green');

	@include media('>tablet') {
		padding: 30px 150px;
	}

	@include media('>wrapper') {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 20px 30px 40px;
		background: rgba(70,70,70,0.5);
		z-index: 99;
	}

	.col3 {
		width: 100%;

		@include media('>wrapper') {
			@include span(3 of 12);
		}
	}

	h3 {
		position: relative;
		font-size: $type-base;
		color: #fff;
		margin-bottom: 0;
		background: url('../images/icons/angle-down_white.svg') no-repeat right center;
		cursor: pointer;

		&.open {
			background: url('../images/icons/angle-up.svg') no-repeat right center;
		}

		@include media('>phone') {
			font-size: 24px;
		}

		@include media('>wrapper') {
			display: none;
		}
	}
	
	.h3desktop {
		display: block;
		background: none;
		margin-top: 43px;
		@include media('<wrapper') {
			display: none;
		}
	}

	.booking-area-form {
		display: none;
		margin-top: 20px;

		@include media('>wrapper') {
			display: block;
			margin-top: 0;

			fieldset {
				margin-bottom: 0;
			}
		}

		label {
			color: #fff;
		}

		input[type="text"], select {
			color: #fff;
			border-color: #fff;

			@include media('>wrapper') {
				border-color: rgba(255,255,255,0.6);
			}
		}

		input[type="submit"] {
			margin-top: 15px;

			@include media('>wrapper') {
				margin-top: 35px;
			}
		}

		.datepicker {
			background: url('../images/icons/calendar.svg') no-repeat center right 15px;
			background-size: 16px;
			cursor: pointer;
			height: 50px;
		}

		select {
			background: url('../images/icons/angle-down_white.svg') no-repeat center right 15px;
			background-size: 16px;
			height: 50px;
			
			option {
				color: color('grey', 'dark');
			}
		}
	}
}