.klaro .cookie-modal, .klaro .cookie-notice {
	font-size: 16px !important;
}

.klaro .cookie-modal .cm-btn {
	padding: 10px 15px !important;
	
	@include media('>500px') { 
		padding: 8px !important;
	}
	
}

.klaro .cookie-notice .cm-btn {
	display: block;
	margin-left: auto;
}

.klaro .cookie-modal .cm-btn, .klaro .cookie-notice .cm-btn {
	margin-right: 0 !important;
}

.klaro .cookie-modal button {
	margin: 0 0 10px;
}

.klaro .cookie-modal .cm-modal .hide {
	top: 10px !important;
	right: 15px !important;
	padding: 0;
	width: auto;
}

.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
	padding: 9px 20px 20px 20px !important;
	
	p {
		hyphens: none;
	}
}

.klaro .cookie-notice .cm-link {
	font-weight: bold;
}

.klaro .cookie-modal .cm-modal .cm-footer-buttons {
	flex-flow: column !important;
	
	@include media('>500px') {
		flex-flow: row !important;
	}
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
	display: none;
}