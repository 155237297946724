.gallery {
	@extend %clearfix;

	.grid-item {
		position: relative;
		float: left;
		margin: 5px;
		background-size: cover;
	}
}

.gallery-item-caption {
	color: #fff;
	position: absolute;
	top: 20px;
	left: 20px;
	bottom: 20px;
	right: 20px;

	span {
		font-size: $type-mid;
		font-weight: 600;
		text-transform: uppercase;
		margin: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		text-align: center;
	}
}

.gallery-item-caption.border {
	background: transparent;
	border: 3px solid rgba(255,255,255,0.5);

	&:hover {
		background: rgba(255,255,255,0.2);
	}
}

.gallery-item-caption.black {
	background: rgba(70,70,70,0.5);

	&:hover {
		background: rgba(0,0,0,0.6);
	}

	span {
		text-transform: none;
		font-size: $type-base;
	}

	hr {
		border-top: 2px solid #fff;
		border-bottom: none;
		width: 50px;
		margin: 15px auto;
	}
}

.gallery-1 {
	background: url('../images/ferienhaus/betten2.jpg') no-repeat;
	width: 100%;
	height: 250px;

	@include media('>tablet') {
		width: 48%;
	}

	@include media('>grid') {
		height: 350px;
	}

	@include media('>wrapper-big') {
		width: 860px;
		height: 573px;
	}

}

.gallery-2 {
	background: url('../images/ferienhaus/eingang.jpg') no-repeat;
	width: 100%;
	height: 250px;

	@include media('>tablet') {
		width: 48%;
	}

	@include media('>grid') {
		height: 350px;
	}

	@include media('>wrapper-big') {
		width: 290px;
		height: 290px;
	}
}

.gallery-3 {
	background: url('../images/ferienhaus/essbereich.jpg') no-repeat;
	width: 100%;
	height: 250px;

	@include media('>tablet') {
		width: 48%;
	}

	@include media('>grid') {
		height: 350px;
	}

	@include media('>wrapper-big') {
		width: 290px;
		height: 273px;
	}
}

.gallery-4 {
	background: url('../images/ferienhaus/wohnraum2.jpg') no-repeat;
	width: 100%;
	height: 250px;

	@include media('>tablet') {
		width: 48%;
	}

	@include media('>grid') {
		height: 350px;
	}

	@include media('>wrapper-big') {
		width: 560px;
		height: 290px;
	}
}

.gallery-5 {
	background: url('../images/ferienhaus/kueche.jpg') no-repeat;
	width: 100%;
	height: 250px;

	@include media('>tablet') {
		width: 48%;
	}

	@include media('>grid') {
		height: 350px;
	}

	@include media('>wrapper-big') {
		width: 290px;
		height: 290px;
	}
}

.gallery-6 {
	background: url('../images/ferienhaus/bad.jpg') no-repeat;
	width: 100%;
	height: 250px;

	@include media('>tablet') {
		width: 48%;
	}

	@include media('>grid') {
		height: 350px;
	}

	@include media('>wrapper-big') {
		width: 290px;
		height: 290px;
	}
}